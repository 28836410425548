@import "./variables.scss";

@mixin interop-form-field($theme) {
    mat-form-field {
        &.mat-form-field-appearance-fill {
            .mat-mdc-text-field-wrapper {
                background-color: $white;
            }
        }

        &.mat-focus {
            .mat-mdc-text-field-wrapper {
                background-color: $light-blue;
            }
        }
    }
}