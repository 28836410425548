@use '@angular/material' as mat;
@use './styles/m3-theme.scss';
@use './styles/button';
@use './styles/form-field';

@include mat.core();


:root {
  @include mat.all-component-themes(m3-theme.$light-theme);
  @include button.interop-button(m3-theme.$light-theme);
  @include form-field.interop-form-field(m3-theme.$light-theme);
  .tertiary-button {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: secondary);
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

body {
  font-family: "Arial";
  font-style: regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  .bold {
    font-style: bold;
    font-weight: 700;
  }
}

h1 {
  font-family: "Arial";
  font-style: regular;
  font-weight: 400;
  font-size: 24px;
}

h2 {
  font-family: "Arial";
  font-style: regular;
  font-weight: 400;
  font-size: 20px;
}

h3 {
  font-family: "Arial";
  font-style: regular;
  font-weight: 400;
  font-size: 18px;
}