$red: #ED1C24;
$dark-red: #810000;
$light-red: #FDE8E9;
$purple: #7126FF;
$light-purple: #F1E9FF;
$light-blue: #00C1FF;
$pink: #FF7798;
$authentic-blak: #000000;
$black: #3C3C3C;
$very-dark-grey: #636363;
$dark-grey: #868686;
$grey: #B1B1B1;
$light-grey: #D8D8D8;
$very-light-grey: #ECECEC;
$white: #FFFFFF;
$very-light-blue: #D9F6FF;
$blue: #337AB7;
$dark-blue: #102263;
$dark-green: #265000;
$green: #3B7D00;
$light-green: #EBF2E5;
$dark-orange: #9D3900;
$orange: #CD3E00;
$light-orange: #FFF5E9;